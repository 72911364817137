<!--
 * @Author: your name
 * @Date: 2020-10-20 14:14:00
 * @LastEditTime: 2020-10-21 09:47:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\RecoverPassword.vue
-->
<template>
  <div class="resetPassword">
    <div class="title mb-4">{{ $t("AuthPage.b11@reset_password") }}</div>
    <!-- <form class="mt-4"> -->
    <div class="form-group">
      <label>{{ $t("AuthPage.845@new_password") }}</label>
      <input
        type="password"
        class="form-control mb-0"
        :placeholder="$t('AuthPage.845@new_password')"
        v-model="form.password"
      />
    </div>
    <div class="form-group">
      <label>{{ $t("AuthPage.80a@confirm_password") }}</label>
      <input
        type="password"
        class="form-control mb-0"
        :placeholder="$t('AuthPage.80a@confirm_password')"
        v-model="form.password_confirmation"
      />
    </div>
    <div class="d-flex justify-content-end">
      <!-- <router-link :to="{ name: 'auth1.sign-in1' }"> -->
      <button
        @click="handleClickSubmit"
        type="submit"
        class="btn btn-primary float-right"
      >
        {{ $t("AuthPage.3ba@submit") }}
      </button>
      <!-- </router-link> -->
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
export default {
  name: "RecoverPassword",
  data: () => ({
    form: {
      password: "",
      password_confirmation: "",
      user_token: "",
      email: "",
    },
    params: "",
  }),
  mounted () {
    // console.log(this.$route.query.token);
  },
  methods: {
    handleClickSubmit () {
      // console.log(window.location.href.split("token=")[1]);
      this.params = window.location.href.split("token=")[1]
      this.form.user_token = this.params.split("&email=")[0]
      this.form.email = this.params.split("&email=")[1]
      // console.log(this.form);
      if (this.form.password == this.form.password_confirmation) {
        const data = { ...this.form };
        this.$http.resetPassword(data).then((res) => {
          if (res.status == 200) {
            localStorage.clear();
            this.$router.push("/auth/sign-in1");
          }
        });
      } else {
        this.$message.info("Please make sure the password is the same");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resetPassword {
  .title {
    font-size: 28px;
    font-weight: 400;
    color: #3f414d;
    margin-bottom: 83px;
  }
}
</style>
